<template>
  <div>
    <a-form layout="inline" @keyup.enter.native="loadData()" style="margin-bottom: 30px">
      <a-row :gutter="24">
        <a-form-item label="订单类型">
          <a-input placeholder="请输订单类型" v-model="form.orderType"></a-input>
        </a-form-item>
        <a-form-item label="交易状态">
          <a-input placeholder="请输入交易状态" v-model="form.statusList"></a-input>
        </a-form-item>
        <a-form-item label="merchantId">
          <a-input placeholder="请输入merchantId" v-model="form.merchantId"></a-input>
        </a-form-item>
        <a-form-item label="merchantNo">
          <a-input placeholder="merchantNo" v-model="form.merchantNo"></a-input>
        </a-form-item>
        <a-form-item label="订单号">
          <a-input placeholder="订单号" v-model="form.orderNo"></a-input>
        </a-form-item>
        <a-form-item label="三方业务身份订单号">
          <a-input placeholder="三方业务身份订单号" v-model="form.getOpenIdentifyNo"></a-input>
        </a-form-item>
        <a-form-item label="银行流水号">
          <a-input placeholder="请输入银行流水号" v-model="form.bankFlowNo"></a-input>
        </a-form-item>
        <a-form-item label="交易状态">
          <a-input placeholder="请输入交易状态" v-model="form.statusList"></a-input>
        </a-form-item>
        <a-button type="primary" @click="loadData()">{{ $t('search') }}</a-button>
        <a-button style="margin-left: 8px" @click="searchReset">{{ $t('reset') }}</a-button>
      </a-row>
    </a-form>
    <a-table
      :columns="columns"
      :dataSource="dataSource"
      :pagination="false"
      :loading="loading"
      ref="table"
      size="middle"
      rowKey="orderId"
      bordered
    ></a-table>
  </div>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'
import store from '@/store'
import { orderList } from '@/api/order'
export default {
  data() {
    return {
      loading: false,
      form: {
        orderType: '', //订单类型
        pageNum: '',
        pageSize: '',
        statusList: '',
        userId: '2', //store.getters.sysUserId
        userNo: store.getters.merchantId,
      },
      columns: [
        {
          title: '金额',
          dataIndex: 'amount',
          key: 'amount',
          align: 'center',
        },
        {
          title: '渠道',
          dataIndex: 'channel',
          key: 'channel',
          align: 'center',
        },
        {
          title: '三方业务身份订单号',
          dataIndex: 'openIdentifyNo',
          key: 'openIdentifyNo',
          align: 'center',
        },
        {
          title: '订单ID',
          dataIndex: 'orderId',
          key: 'orderId',
          align: 'center',
        },
        {
          title: '订单号',
          dataIndex: 'orderNo',
          key: 'orderNo',
          align: 'center',
        },
        {
          title: '订单类型',
          dataIndex: 'orderType',
          key: 'orderType',
          align: 'center',
        },
        {
          title: '订单状态',
          dataIndex: 'status',
          key: 'status',
          align: 'center',
        },
      ],
      dataSource: [],
    }
  },
  created() {
    this.loadData()
  },
  mounted() {},
  methods: {
    loadData() {
      orderList(this.form).then((res) => {
        this.dataSource = res.data.list
        console.log(this.dataSource)
      })
    },
    searchReset() {
      for (let i in this.form) {
        this.form[i] = ''
      }
    },
  },
}
</script>

<style scoped>
/* @import '../../assets/less/common.less'; */
</style>
