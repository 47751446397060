<template>
  <a-spin tip="loading..." :spinning="loading" size="large">
    <div class="appContainer">
      <div class="Item">
        <div class="mainContent">
          <div class="label">商户编号</div>
          <div class="ItemContent">{{ detail.userNo }}</div>
        </div>
        <div class="btnCon">
          <div class="button" @click="copy(detail.userNo)">复制</div>
        </div>
      </div>
      <div class="Item">
        <div class="mainContent">
          <div class="label">商户名称</div>
          <div class="ItemContent">{{ detail.userName }}</div>
        </div>
        <div class="btnCon"></div>
      </div>

      <div class="Item">
        <div class="mainContent">
          <div class="label">电话</div>
          <div class="ItemContent">{{ detail.mobile }}</div>
        </div>
        <div class="btnCon"></div>
      </div>
      <div class="Item">
        <div class="mainContent">
          <div class="label">whatsApp</div>
          <div class="ItemContent">{{ detail.whatsApp }}</div>
        </div>
        <div class="btnCon"></div>
      </div>
      <div class="Item">
        <div class="mainContent">
          <div class="label">邮箱</div>
          <div class="ItemContent">{{ detail.email }}</div>
        </div>
        <div class="btnCon"></div>
      </div>
      <div class="Item">
        <div class="mainContent">
          <div class="label">平台公钥</div>
          <div class="ItemContent" style="min-height: 72px">{{ detail.serverPublicKey }}</div>
        </div>
        <div class="btnCon">
          <!-- <div class="button" style="margin-bottom: 10px" @click="generateServerPublicKeyMtn">重新生成</div> -->
          <div class="button" @click="copy(detail.serverPublicKey)">复制</div>
        </div>
      </div>
      <div class="Item">
        <div class="mainContent">
          <div class="label">商户公钥</div>
          <textarea style="min-height: 135px; width: 600px" v-model="detail.clientPublicKey"></textarea>
        </div>
        <div class="btnCon">
          <a-popconfirm title="是否确认保存？" ok-text="确认" cancel-text="取消" @confirm="saveSecretKeyMtn">
            <div class="button">保存</div>
          </a-popconfirm>
        </div>
      </div>
      <div class="Item">
        <div class="mainContent">
          <div class="label">白名单(以英文分号隔开，可多个)</div>
          <textarea style="min-height: 135px; width: 600px" v-model="whiteList"> </textarea>
        </div>
        <div class="btnCon">
          <a-popconfirm title="是否确认保存？" ok-text="确认" cancel-text="取消" @confirm="modifyUserWhiteList">
            <div class="button">保存</div>
          </a-popconfirm>
        </div>
      </div>
    </div>
  </a-spin>
</template>
<script>
import { mapGetters } from 'vuex'
import { message } from 'ant-design-vue/es'
import { getMerchantById, generateServerPublicKey, saveSecretKey } from '../../../api/pay.js'
import { getUserWhtieList, updateUserWhtieList } from '@/api/whitelist.js'

export default {
  data() {
    return {
      loading: false,
      detail: {},
      whiteList: '',
    }
  },
  computed: {
    ...mapGetters(['userId', 'userId']),
  },
  created() {
    this.getMerchantByIdMtn()
    this.queryUserWhiteList()
  },
  methods: {
    queryUserWhiteList() {
      getUserWhtieList({ userId: this.userId }).then((res) => {
        if (res.code == 2000) {
          this.whiteList = res.data.content
        }
      })
    },
    modifyUserWhiteList() {
      if (!this.whiteList) {
        this.$message.error('请输入白名单!')
        return
      }
      updateUserWhtieList({ userId: this.userId, content: this.whiteList, whiteType: 1 }).then((res) => {
        if (res.code == '2000') {
          this.$message.success('白名单更新成功')
        }
      })
    },
    copy(e) {
      //复制方法
      let input = document.createElement('input')
      input.value = e
      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
      message.success('复制成功')
    },
    getMerchantByIdMtn() {
      this.loading = true
      let data = new FormData()
      data.append('id', this.userId)
      getMerchantById(data).then((res) => {
        this.detail = res.data
        this.loading = false
      })
    },
    saveSecretKeyMtn() {
      this.loading = true
      let data = new FormData()
      data.append('id', this.userId)
      data.append('serverPublicKey', this.detail.serverPublicKey)
      data.append('clientPublicKey', this.detail.clientPublicKey)
      saveSecretKey(data).then((res) => {
        if (res.code == 2000) {
          message.success('保存成功')
        }
        this.loading = false
      })
    },
    generateServerPublicKeyMtn() {
      this.loading = true
      let data = new FormData()
      data.append('id', this.userId)
      generateServerPublicKey(data).then((res) => {
        this.loading = false
        if (res.code == 2000) {
          this.detail.serverPublicKey = res.data
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
textarea {
  resize: none;
}

.submit {
  margin: 0px 16vw;
}
.Item {
  width: 800px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 16px;
  .mainContent {
    .label {
      margin-bottom: 10px;
    }
    .ItemContent {
      width: 600px;
      min-height: 28px;
      background: #d7d7d7;
      border-radius: 3px;
      padding: 4px 8px;
      height: auto;
      overflow: auto;
      word-break: break-all;
    }
  }
  .btnCon {
    height: 100%;
    margin-left: 20px;
    .button {
      color: #fff;
      background: #1890ff;
      border-radius: 4px;
      padding: 4px 8px;
      cursor: pointer;
    }
  }
}
</style>
