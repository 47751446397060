import { axios } from '@/utils/request'

export function getUserWhtieList(parameter = {}) {
  return axios({
    url: '/whiteList/getWhiteListByUserId',
    method: 'post',
    data: parameter,
    type: 'form',
  })
}

export function updateUserWhtieList(parameter = {}) {
  return axios({
    url: '/whiteList/updateWhiteInfo',
    method: 'post',
    data: parameter,
  })
}
